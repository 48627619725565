AgrimarketControllers.filter('pdfLinkContract', function (configurationFactory) {
    return function (value) {

        var url = configurationFactory.Url['Api'] + 'PdfGenerate/GetContract?h=[h]&k=[k]' ;

        return url.replace('[k]',value.k).replace('[h]',value.h);
    };
});


AgrimarketControllers.filter('pdfLinkDocument', function (configurationFactory) {
    return function (value) {

        var url = configurationFactory.Url['Api'] + 'PdfGenerate/GetDocument?h=[h]&k=[k]' ;

        return url.replace('[k]',value.k).replace('[h]',value.h);
    };
});

AgrimarketControllers.filter('pdfLinkContractBase', function (configurationFactory) {
    return function (value) {

        var url = configurationFactory.Url['Api'] + 'PdfGenerate/GetContractBase?h=[h]&k=[k]' ;

        return url.replace('[k]',value.k).replace('[h]',value.h);
    };
});

AgrimarketControllers.filter('pdfLinkContractOption', function (configurationFactory) {
    return function (value) {

        var url = configurationFactory.Url['Api'] + 'PdfGenerate/GetContractOption?h=[h]&k=[k]' ;

        return url.replace('[k]',value.k).replace('[h]',value.h);
    };
});

AgrimarketControllers.filter('pdfLinkExerciseOption', function (configurationFactory) {
    return function (value) {

        var url = configurationFactory.Url['Api'] + 'PdfGenerate/GetExerciseOption?h=[h]&k=[k]' ;

        return url.replace('[k]',value.k).replace('[h]',value.h);
    };
});

AgrimarketControllers.filter('pdfLinkProjPdf', function (configurationFactory) {
    return function (value) {

        var url = configurationFactory.Url['Api'] + 'PdfGenerate/GetProjPdf?id=' ;

        return url   + value ;
    };
});




