AgrimarketControllers.controller('userOfferController', ['$scope', '$rootScope', '$location', 'configurationFactory', 'offerService', 'enumFactory', '$routeParams', 'colonnesOffersFirmUser', 'colonnesOffersCommitmentUser', 'colonnesOffersFirmTc', 'colonnesOffersCommitmentTc', 'colonnesOffersOptionUser', 'colonnesOffersOptionTc', 'smsAlertService', 'colonnesOffersBlankContract', 'userService', 'colonnesOffersCommitmentSurfaceUser', 'colonnesOffersCommitmentSurfaceTc', 'colonnesOffersMultiCropsUser', 'colonnesOffersMultiCropsTc',
    function ($scope, $rootScope, $location, configurationFactory, offerService, enumFactory, $routeParams, colonnesOffersFirmUser, colonnesOffersCommitmentUser, colonnesOffersFirmTc, colonnesOffersCommitmentTc, colonnesOffersOptionUser, colonnesOffersOptionTc, smsAlertService, colonnesOffersBlankContract, userService, colonnesOffersCommitmentSurfaceUser, colonnesOffersCommitmentSurfaceTc, colonnesOffersMultiCropsUser, colonnesOffersMultiCropsTc) {
        $scope.viewMode = $routeParams.typeOffer;
        $scope.idContractCommitment = $routeParams.idContractCommitment;
        $scope.quantity = $routeParams.quantity;
        $scope.targetPrice = $routeParams.targetPrice;
        $scope.idTypeFixationOnlyTargetPrice = $routeParams.idTypeFixationOnlyTargetPrice;
        $scope.idFirstComponentContractOrContractBase = $routeParams.idFirstComponentContractOrContractBase;
        $scope.enumFactory = enumFactory;
        $scope.enumTypeOffer = enumFactory.TypeOffer;
        $rootScope.offer = {};
        $scope.Product = { IdProduct: null };
        $scope.offersOpen = [];
        $scope.maxOffers = 10;
        $scope.pageNo = 1;
        $scope.allowUserToSubscribreToday = false;
        $scope.userNotSelected = $rootScope.CurrentUserRole === "TC" && ($rootScope.selectedClientFromTc === undefined || $rootScope.selectedClientFromTc === null);
        $scope.data = { reverse: false, sortKey: "Order" };
        $scope.IsModuleOptionActive = $rootScope.Modules.ModuleOption.Active;
        $scope.IsModuleBlankContractActive = $rootScope.Modules.ModuleContratVierge.Active;
        $scope.IsModuleMultiCropsActive = $rootScope.Modules.ModuleMultiCampagne.Active;
        $scope.enumTypeFixations = enumFactory.TypeFixations;
        $scope.lstProducts = [];
        var setFilters = true;
        $scope.Offer_SellLegendIcoIsVisible = $rootScope.editableObjectsFactory.EditableObjects.Offer_Sell.IsVisible || $rootScope.modeEdition;
        $scope.Offer_TargetPriceLegendIcoIsVisible = $rootScope.editableObjectsFactory.EditableObjects.Offer_TargetPrice.IsVisible || $rootScope.modeEdition;
        $scope.Offer_AlertLegendIcoIsVisible = $rootScope.editableObjectsFactory.EditableObjects.Offer_Alert.IsVisible || $rootScope.modeEdition;

        if (!$rootScope.paramSelectTcUserGlobally)
            $rootScope.selectedClientFromTcForSubscribe = undefined;

        var AllowUserToSubscribreToday = function () {
            offerService.AllowUserToSubscribreToday(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.allowUserToSubscribreToday = false;
                } else {
                    $scope.allowUserToSubscribreToday = data === "true";
                }
            });
        };

        var Init = function () {
            if ($rootScope.CurrentUserRole === "User") {
                // Offres fermes
                $scope.tableOfferFirmDataVisible = colonnesOffersFirmUser.data.DataVisible.split(',');
                $scope.tableOfferFirmLibelle = colonnesOffersFirmUser.data.ColumnName.split(',');

                // Offres Multi campagnes
                $scope.tableOfferMultiCropsDataVisible = colonnesOffersMultiCropsUser.data.DataVisible.split(',');
                $scope.tableOfferMultiCropsLibelle = colonnesOffersMultiCropsUser.data.ColumnName.split(',');

                // Offres d'engagements
                $scope.tableOfferCommitmentDataVisible = colonnesOffersCommitmentUser.data.DataVisible.split(',');
                $scope.tableOfferCommitmentLibelle = colonnesOffersCommitmentUser.data.ColumnName.split(',');

                // Offres d'option
                $scope.tableOfferOptionDataVisible = colonnesOffersOptionUser.data.DataVisible.split(',');
                $scope.tableOfferOptionLibelle = colonnesOffersOptionUser.data.ColumnName.split(',');

                // Offres d'engagements de surface
                $scope.tableOfferCommitmentSurfaceDataVisible = colonnesOffersCommitmentSurfaceUser.data.DataVisible.split(',');
                $scope.tableOfferCommitmentSurfaceLibelle = colonnesOffersCommitmentSurfaceUser.data.ColumnName.split(',');


            } else if ($rootScope.CurrentUserRole === "TC") {
                // Offres fermes
                $scope.tableOfferFirmDataVisible = colonnesOffersFirmTc.data.DataVisible.split(',');
                $scope.tableOfferFirmLibelle = colonnesOffersFirmTc.data.ColumnName.split(',');

                // Offres Multi campagnes
                $scope.tableOfferMultiCropsDataVisible = colonnesOffersMultiCropsTc.data.DataVisible.split(',');
                $scope.tableOfferMultiCropsLibelle = colonnesOffersMultiCropsTc.data.ColumnName.split(',');

                // Offres d'engagements
                $scope.tableOfferCommitmentDataVisible = colonnesOffersCommitmentTc.data.DataVisible.split(',');
                $scope.tableOfferCommitmentLibelle = colonnesOffersCommitmentTc.data.ColumnName.split(',');

                // Offres d'option
                $scope.tableOfferOptionDataVisible = colonnesOffersOptionTc.data.DataVisible.split(',');
                $scope.tableOfferOptionLibelle = colonnesOffersOptionTc.data.ColumnName.split(',');

                // Offres contrat vierge
                $scope.tableOfferBlankContractDataVisible = colonnesOffersBlankContract.data.DataVisible.split(',');
                $scope.tableOfferBlankContractLibelle = colonnesOffersBlankContract.data.ColumnName.split(',');

                // Offres d'engagements de surface
                $scope.tableOfferCommitmentSurfaceDataVisible = colonnesOffersCommitmentSurfaceTc.data.DataVisible.split(',');
                $scope.tableOfferCommitmentSurfaceLibelle = colonnesOffersCommitmentSurfaceTc.data.ColumnName.split(',');
            }

            if (!$scope.userNotSelected) {
                var index = $scope.tableOfferFirmDataVisible.indexOf('GroupContacts;liste');
                if (index !== -1) {
                    $scope.tableOfferFirmDataVisible.splice(index, 1);
                    $scope.tableOfferFirmLibelle.splice(index, 1);
                }
            }
            $scope.GetOffersOpen();
            AllowUserToSubscribreToday();
        };

        $scope.AsChangedCrop = function () {
            // On reset les filtres
            setFilters = true;
            $scope.Product.IdProduct = null;
            $scope.pageNo = 1;
            $scope.GetOffersOpen();
        };

        $scope.AsChangedProduct = function () {
            $scope.pageNo = 1;
            $scope.GetOffersOpen();
        };

        $scope.GoToSubscribe = function () {
            if ($scope.offerSelected.IdTypeOffer === $scope.enumTypeOffer.Engagement || $scope.offerSelected.IdTypeOffer === $scope.enumTypeOffer.EngagementSurface)
                $rootScope.idTypeCommitmentSelected = $scope.offerSelected.IdTypeCommitment;
            else
                $rootScope.idTypeCommitmentSelected = null;

            switch ($scope.idTypeFixation) {
                case enumFactory.TypeFixations.Base.IdTypeFixation:
                    $location.path('/user/subscribe/contractBase/' + ($scope.idContractCommitment ? $scope.idContractCommitment : '') + '/' + ($scope.idFirstComponentContractOrContractBase ? $scope.idFirstComponentContractOrContractBase : '') + '/' + ($scope.quantity ? $scope.quantity : '') + '/' + ($scope.targetPrice ? $scope.targetPrice : ''));
                    break;
                case enumFactory.TypeFixations.Future.IdTypeFixation:
                case enumFactory.TypeFixations.GlobalPrice.IdTypeFixation:
                    $location.path('/user/subscribe/contractFirm/' + ($scope.idContractCommitment ? $scope.idContractCommitment : '') + '/' + $scope.idTypeFixation + '/' + ($scope.idFirstComponentContractOrContractBase ? $scope.idFirstComponentContractOrContractBase : '') + '/' + ($scope.quantity ? $scope.quantity : '') + '/' + ($scope.targetPrice ? $scope.targetPrice : ''));
                    break;
                default:
                    if ($scope.offerSelected.IdTypeOffer === $scope.enumTypeOffer.EngagementSurface)
                        $location.path('/user/subscribe/contractSurfaceCommitment');
                    else
                        $location.path('/user/subscribe/contractCommitment');
                    break;
            }
        };

        $scope.GoToSubscribeOption = function (offer, idExpiry, idTypeOrder) {
            $rootScope.offerSelected = {
                IdOfferSelected: offer.IdOffer,
                IdProductSelected: offer.IdProduct,
                IdExpirySelected: idExpiry,
                IdTypeOptionSelected: offer.IdTypeOption,
                IdTypeOrderSelected: idTypeOrder,
                IdCropSelected: $rootScope.crop.IdCrop
            };
            $location.path('/user/subscribe/contractoption/');
        };

        $scope.GoToSubscribeBlankContract = function () {
            $location.path('/user/subscribe/contractBlank/');
        };

        $scope.GoToSubscribeMultiCropsContract = function () {
            $location.path('/user/subscribe/contractMultiCrops/');
        };

        $scope.SubscribeActionClick = function (offer, idExpiry, idTypeOrder, idTypeFixation, openPopUp) {
            if (offer) {
                $rootScope.idOfferSelected = !offer.IdOffer ? offer.IdOfferParent : offer.IdOffer;
                $scope.offerSelected = offer;
                $rootScope.idExpirySelected = idExpiry;
            }
            if (idTypeOrder)
                $rootScope.idTypeOrderSelected = idTypeOrder;
            if (idTypeFixation)
                $scope.idTypeFixation = idTypeFixation;

            // Si le TC a déjà sélectionner sélectionné son agri on peux rediriger vers la souscription
            if ($rootScope.selectedClientFromTcForSubscribe || $rootScope.CurrentUserRole === "User") {
                if ($scope.offerSelected.IdTypeOffer === $scope.enumTypeOffer.Option)
                    $scope.GoToSubscribeOption();
                else if ($scope.offerSelected.IdTypeOffer === $scope.enumTypeOffer.ContratVierge)
                    $scope.GoToSubscribeBlankContract();
                else if ($scope.offerSelected.IdTypeOffer === $scope.enumTypeOffer.MultiCampagne)
                    $scope.GoToSubscribeMultiCropsContract();
                else
                    $scope.GoToSubscribe();
            }
            else // Sinon on ouvre la popUp de sélection des agris du TC
                openPopUp();
        };

        $scope.SubmitPopUpTcUsers = function () {
            userService.SaveClientSelectedFromTC($rootScope.selectedClientFromTcForSubscribe.IdUser, false, function (data, status) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.SubscribeActionClick();
                }
            });
        };



        $scope.SetSelectedClientFromTc = function (client) {
            $rootScope.selectedClientFromTcForSubscribe = client;
        };

        $scope.GetOffersOpen = function () {
            $scope.loading = true;
            offerService.GetDataOfferPaginateWithFilter($rootScope.crop.IdCrop, $routeParams.typeOffer, $scope.Product.IdProduct ? $scope.Product.IdProduct : '', $scope.idContractCommitment ? $scope.idContractCommitment : '', $scope.maxOffers, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.offersOpen = [];
                    data.LstOfferOpenVm.forEach(function (item) {

                        item.showAllExpiryOffer = true;
                        item.urlAttachment = configurationFactory.Url['Api'] + 'Upload/GetInlineAttachment?p=' + item.AttachmentPrivateLabel;

                        var actionFuturePrice = item.MarketPrice > 0;
                        var actionGlobalPrice = item.GlobalPrice > 0;

                        if ($rootScope.parambActivedSubscribeOfferWithZeroPrice == "1") {
                            if (item.MarketPrice == 0) actionFuturePrice = item.MarketPrice == 0;
                            if (item.GlobalPrice == 0) actionGlobalPrice = item.GlobalPrice == 0;
                        }

                        item.showFixationBaseActionSold = item.BasePriceWithCurrencyUnit && item.CanSoldBase && item.TypeFixationTypeOrders.SoldBase;
                        item.showFixationBaseActionTargetPrice = item.BasePriceWithCurrencyUnit && item.CanTargetPriceBase && item.TypeFixationTypeOrders.TargetPriceBase;
                        item.showFixationBaseActions = item.showFixationBaseActionSold || item.showFixationBaseActionTargetPrice;

                        item.showFixationFuturePriceActionSold = actionFuturePrice && !item.CanNotSubscribeFuture && item.CanSold && item.TypeFixationTypeOrders.SoldFuture;
                        item.showFixationFuturePriceActionTargetPrice = actionFuturePrice && !item.CanNotSubscribeFuture && item.CanTargetPrice && item.TypeFixationTypeOrders.TargetPriceFuture;
                        item.showFixationFuturePriceActions = item.showFixationFuturePriceActionSold || item.showFixationFuturePriceActionTargetPrice;

                        item.showFixationGlobalPriceActionSold = actionGlobalPrice && item.CanSold && item.TypeFixationTypeOrders.SoldGlobalPrice;
                        item.showFixationGlobalPriceActionTargetPrice = actionGlobalPrice && item.CanTargetPrice && item.TypeFixationTypeOrders.TargetPriceGlobalPrice;
                        item.showFixationGlobalPriceActions = item.showFixationGlobalPriceActionSold || item.showFixationGlobalPriceActionTargetPrice;

                        if (item.QuotesExpiryOfferManagerVM && item.QuotesExpiryOfferManagerVM.length > 0) {
                            item.QuotesExpiryOfferManagerVM.forEach(function (exp) {

                                exp.showFixationBaseActionSold = exp.BasePriceWithCurrencyUnit && exp.CanSoldBase && item.TypeFixationTypeOrders.SoldBase;
                                exp.showFixationBaseActionTargetPrice = exp.BasePriceWithCurrencyUnit && exp.CanTargetPriceBase && item.TypeFixationTypeOrders.TargetPriceBase;
                                exp.showFixationBaseActions = exp.showFixationBaseActionSold || exp.showFixationBaseActionTargetPrice;

                                exp.showFixationFuturePriceActionSold = exp.Price > 0 && !item.CanNotSubscribeFuture && exp.CanSold && item.TypeFixationTypeOrders.SoldFuture;
                                exp.showFixationFuturePriceActionTargetPrice = exp.Price > 0 && !item.CanNotSubscribeFuture && exp.CanTargetPrice && item.TypeFixationTypeOrders.TargetPriceFuture;
                                exp.showFixationFuturePriceActions = exp.showFixationFuturePriceActionSold || exp.showFixationFuturePriceActionTargetPrice;

                                exp.showFixationGlobalPriceActionSold = exp.PriceUser > 0 && exp.CanSold && item.TypeFixationTypeOrders.SoldGlobalPrice;
                                exp.showFixationGlobalPriceActionTargetPrice = exp.PriceUser > 0 && exp.CanTargetPrice && item.TypeFixationTypeOrders.TargetPriceGlobalPrice;
                                exp.showFixationGlobalPriceActions = exp.showFixationGlobalPriceActionSold || exp.showFixationGlobalPriceActionTargetPrice;
                            });
                        }
                        $scope.offersOpen.push(item);
                    });
                    $scope.total = data.Total;
                    $scope.isAlertSmsActive = data.IsAlertSmsActive;

                    if (setFilters) {
                        $scope.lstProducts = data.LstProduct;
                        setFilters = false;
                    }

                    $scope.loading = false;

                    // if ($scope.idTypeFixationOnlyTargetPrice) { //cas où on vient du dupliquer de la suivi contrat v2
                    //     if ($scope.offersOpen.length === 1 && $scope.offersOpen[0].QuotesExpiryOfferManagerVM.length === 0) {
                    //         $scope.GoToSubscribe($scope.offersOpen[0], $scope.offersOpen[0].IdFirstExpiry, enumFactory.TypeOrders.Prixobjectif.IdTypeOrder, Number($scope.idTypeFixationOnlyTargetPrice));
                    //     }
                    // }
                }
            });
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.GetOffersOpen();
        };

        $scope.GetData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.GetOffersOpen();
        };

        $scope.SetLimitsAlertSmsPopUp = function (idExpiry, limitMin, limitMax, openPopUp) {
            $scope.popupSmsAlertErrorMessage = "";
            offerService.GetOfferPrice(idExpiry, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.expirySelected = {
                        idExpiry: idExpiry,
                        price: data.FinalPrice,
                        symbol: data.Symbol,
                        limitMin: limitMin,
                        limitMax: limitMax
                    };
                    openPopUp();
                }
            });

        };

        $scope.ChkSmsAlertIsValid = function () {
            $scope.popupSmsAlertErrorMessage = smsAlertService.CheckSmsAlertIsValid($scope.expirySelected.limitMax, $scope.expirySelected.limitMin, $scope.expirySelected.price);

            if ($scope.popupSmsAlertErrorMessage !== "") {
                return false;
            }
            else {
                return true;
            }
        };

        $scope.SaveSmsAlertLimit = function () {
            if (!$scope.expirySelected.limitMin)
                $scope.expirySelected.limitMin = "";
            if (!$scope.expirySelected.limitMax)
                $scope.expirySelected.limitMax = "";

            smsAlertService.SaveUserExpirySmsLimits($scope.expirySelected.idExpiry, $scope.expirySelected.limitMin, $scope.expirySelected.limitMax, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    Init();
                }
            });
        };

        $rootScope.onRefresh = function () { Init(); };
        Init();
    }]);
