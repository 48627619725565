AgrimarketControllers.controller('resetPasswordController', ['$scope', '$routeParams', 'configurationFactory', '$rootScope', 'currentUserFactory',
    function ($scope, $routeParams, configurationFactory, $rootScope, currentUserFactory) {

        $scope.errorResetPasswordMessage = '';
        $scope.resetPasswordMessage = '';
        $rootScope.currentUserRole = '';
        currentUserFactory.User = null;
        currentUserFactory.Connection = null;
        $rootScope.skin = configurationFactory.Skin;
        $rootScope.isLogged = false;
        $rootScope.loading = false;
        $scope.keyPassword = $routeParams.keyPassword;
        $rootScope.$broadcast("$destroy");
        sessionStorage.clear();
    }]);