AgrimarketServices.service('dateService', [
    function() {
        this.compareDate = function(date1, date2) {
            var newDate1 = date1.split('/');
            var newDate2 = date2.split('/');

            if (newDate1[2] == newDate2[2] && newDate1[1] == newDate2[1] && newDate1[0] == newDate2[0]) {
                return false;
            }

            if (newDate1[2] == newDate2[2] && newDate1[1] == newDate2[1] && newDate1[0] > newDate2[0]) {
                return false;
            }

            if (newDate1[2] == newDate2[2] && newDate1[1] > newDate2[1]) {
                return false;
            }

            if (newDate1[2] > newDate2[2]) {
                return false;
            }

            return true;
        };

        this.compareDate1SuperiorDate2 = function (date1, date2) {
            if (date1 && date2 && date1 >= date2)
                return true;
            else
                return false;
        };

        this.compareDateOverride = function (date1, date2) {
            var newDate1 = date1.split('/');
            var newDate2 = date2.split('/');

            if (newDate1[2] == newDate2[2] && newDate1[1] == newDate2[1] && newDate1[0] == newDate2[0]) {
                return true;
            }

            if (newDate1[2] == newDate2[2] && newDate1[1] == newDate2[1] && newDate1[0] > newDate2[0]) {
                return false;
            }

            if (newDate1[2] == newDate2[2] && newDate1[1] > newDate2[1]) {
                return false;
            }

            if (newDate1[2] > newDate2[2]) {
                return false;
            }

            return true;
        };

        this.VerifDateIsCalendar = function(date) {
            var dateCheck = date.split("/");
            dateCheck[1] -= 1;
            var newDate = new Date(dateCheck[2], dateCheck[1], dateCheck[0]);
            return newDate.getFullYear() != dateCheck[2] || newDate.getMonth() != dateCheck[1] ? false : true;
        };

        this.getFormatDateFromApi = function(date) {
            var newDate = date.substr(0, 10).split('-');
            return newDate[2] + "/" + newDate[1] + "/" + newDate[0];
        };

        this.ConvertDateToDateAPI = function(date) {
            var newDate = date.split('/');
            return new Date(Date.UTC(newDate[2], newDate[1] - 1, newDate[0]));
        };

        this.toStringddMMyyyy = function(date) {
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            var day2 = '' + day;
            var month2 = '' + month;
            
            if (day < 10)
                day2 = '0' + day;
            if (month < 10)
                month2 = '0' + month;

            return day2 + '/' + month2 + '/' + year;

        };
    }
]);